<template>
    <div class="mb-100 ">
        <div class="divider-full"></div>
        <div class="cotainer-standar text-center"> 
            <div class="container-standar mt-10 mb-10 pad-20 ">
                <div class="row">
                    <div class="col-lg-8 pad-20 card shadow"> 
                        <h4 class="mb-40 font-34 font-res-25 width-90 text-left font-weight-7 text-dark">
                            {{ detail.title }}
                            <br>
                            <span class="text-grey font-18 font-weight-4">{{ detail.publish_date }}</span>
                        </h4>
                        <div class="w-100">
                            <img class=contain-img :src="detail.picture_path" alt="image">
                        </div>
                           
                        <p class="text-justify mt-10 font-20 font-res-18" v-html="detail.content"></p>      
                    </div>
                    <div class="col-lg-4 pad-res-1">
                        <div class="card shadow text-left pad-10 ">
                            <span class="d-inline-block bor-bot-1 width-100 pt-2 pb-2 font-22 font-weight-7 text-primary">Berita Terkait</span>
                            <template v-for="(berita, index) in related">
                                <b-link 
                                    :to="'/berita/' + berita.id"
                                    @click.native="$router.go()" 
                                    class="row suggest-img" v-bind:key="index"
                                >
                                    <div class="col-4 cont-img-100">
                                        <!-- <img :src="berita.picture_path" alt=""> -->
                                        <b-img
                                            style="
                                            border-radius: 5px 5px;
                                            height: 300px;
                                            width: 300px;
                                            object-fit: cover;
                                            "
                                            :src="berita.picture_path"
                                        ></b-img>
                                    </div>
                                    <span class="col-8 title font-16  font-res-14 font-weight-6 ">
                                        {{ berita.title | shotDescription(50) }}
                                        <br>
                                        <span class="text-grey  font-res-14 font-weight-6 mt-2 d-inline-block">{{ berita.publish_date }}</span>
                                    </span>
                                </b-link>
                            </template>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            detail: {},
            related: []
        }
    },
    async mounted(){
        this.$store.commit("SET_BG", false);
        await this.getDetail(this.$route.params.id)
        this.getBerita()
    },
    methods: {
        async getDetail(id) {
            await this.$axios.get('/news/' + id)
            .then(response => {
                this.detail = response.data
            })
        },
        getBerita() {
            this.$axios.get('/news')
            .then(response => {
                this.related = response.data.filter( (value, index) => index < 5 && this.detail.id != value.id)
            })
        }
    }
}
</script>